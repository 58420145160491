@import "bootstrap";
/*@import "icon-list";*/

// **********************************
// Bootstrap overrides and extensions
.fs-7 {
  font-size: 0.925em;
}
@include media-breakpoint-down(md) {
  .lead {
    font-size: 1.1rem;
  }
}

// Boostrap only has responsive h1-h4; add h5-h6
h5, .h5 {
  font-size: calc(1rem + 0.2vw);
  line-height: calc(1.1rem + 0.5vw);
}
.intro h5, .intro .h5 {
  font-size: calc(1rem + 0.2vw);
  line-height: calc(1.1rem + 0.6vw);
  font-weight: 400;
}
h6, .h6 {
  font-size: calc(0.9rem + 0.2vw);
  line-height: calc(1.1rem + 0.4vw);
}

// ****************************
// General Styles for All Pages
body {
/*  font-family: "Helvetica Neue", var(--bs-body-font-family);*/
  --dm-orange: #e86f0f;
  --dm-orange-rgb: 232, 111, 15;
  --dm-orange-7: #b66227;
  --dm-orange-7-rgb: 182, 98, 39;
  --dm-orange-8: #91552a;
  --dm-orange-8-rgb: 145, 85, 42;
  --dm-orange-9: #663c1d;
  --dm-orange-9-rgb: 102, 60, 29;
}

main a, footer a {
  text-decoration: none;
  color: $blue-600;
}
main a:hover, footer a:hover {
  text-decoration: underline;
}

main h2 {
  display: inline-block;
  border-bottom: 3px solid var(--dm-orange-7);
  padding-bottom: 6px;
  font-weight: 600;
  margin-bottom: 1rem;
}
/*main h3 {
  color: var(--bs-tertiary-color);
}*/
main i.bi {
  color: var(--dm-orange-7);
}

.navbar { 
  background-color: var(--dm-orange) !important;
  font-size: 1.2em;
}
.navbar-brand, .home .intro h1 {
  font-family: Futura, "Trebuchet MS", "Century Gothic Medium", "Century Gothic", Tahoma, sans-serif;  
}
.navbar-brand {
  padding: 0 30px 0 46px;
  background: transparent url("../img/davidmarble-logo.png") 0 3px/36px 36px no-repeat;
  font-size: 28px;
}

@include media-breakpoint-down(lg) {
  nav .social-links {
    flex-direction: row;
    font-size: 1.5em;
    margin-top: 0.6em;
    border-top: 1px dotted var(--bs-secondary-color);
    text-align: center;
    justify-content: center !important;
  }
  nav .social-links a {
    padding: 0.5em 1.5em 0.3em;
  }
  nav .social-links a:first-child {
/*    padding-left: 0;*/
  }
}

footer .social-links a {
  padding-right: 0.8em;
}
footer .social-links a:first-child {
  padding-left: 0.8em;
}
footer .social-links a:hover {
  color: #333;
}
@include media-breakpoint-down(lg) {
  footer {
    text-align: center;
  }
  footer .social-links {
    display: block;
    margin-top: 1em;
    border: 0 !important;
    font-size: 1.5em;
  }
  footer .social-links a {
    padding: 0.7em;
  }
}

.intro-split .intro-img {
  width: 100%;
  margin-bottom: 1em;
}
div.img-dm, .img-dm img {
  height: 80px;
  width: 80px;
  margin: auto;
}
.intro-split .intro-info {
  width: 100%;
  text-align: center;
}

@media (min-width: 451px) {
  .intro-split .intro-img {
    width: 128px;
  }
  div.img-dm, .img-dm img {
    height: 100px;
    width: 100px;
    margin-left: 0;
  }
  .intro-split .intro-info {
    width: 66.6667%;
    text-align: left;
  }
}

@include media-breakpoint-up(md) {
  .intro-split .intro-img {
    width: 180px;
  }
  div.img-dm, .img-dm img {
    height: 150px;
    width: 150px;
  }
  .intro-split .intro-info {
  }
}

@include media-breakpoint-up(lg) {
  .intro-split .intro-info {
    width: 72%;
  }
}

@include media-breakpoint-up(xl) {
  .intro-split .intro-info {
    width: 77%;
  }
}

hr.mini-break, .area hr.mini-break {
  margin: 26px auto 16px;
  width: 50%;
  border-top-color: var(--bs-primary-border-subtle);
  border-bottom: none;
}
.area img.slide {
  border: 1px solid #ddd;
  margin: 15px 15px 15px 0;
  padding: 5px;
  vertical-align: top;
}

// ********
// Homepage
.home .intro {
  background: #eee url("../img/dunnottar-castle.jpg") left -20px top 0px/1680px 560px no-repeat;
}
.home .intro-photo img {
  display: block;
  width: 260px;
  height: 180px;
  object-fit: cover;
  object-position: top;
}
.home .intro-text .card {
  background-color: rgb(220, 229, 237, 0.8);
/*  border-color: rgba(var(--bs-secondary-color-rgb), .5);*/
  border-style: none;
}
.home .intro h1 {
  margin-left: -2px;
/*  font-weight: 600;*/
}
.home .intro h4 {
  color: var(--dm-orange-8);
}
@include media-breakpoint-up(md) {
  .home .intro {
    background: #eee url("../img/dunnottar-castle.jpg") left 0% bottom 42%/cover;  
  }
  .home .intro-photo img {
    height: unset;
    width: unset;
    max-width: 100%;
  }
}
@include media-breakpoint-up(lg) {
  .home .intro h1 {
    letter-spacing: -1px;
  }
}

.expertise i.bi {
  font-size: 1.75em;
  line-height: 1em;
  margin-right: 1rem !important;
  flex-shrink: 0;
  margin-top: 2px;
}
.expertise .logos img {
/*  filter: grayscale(1);*/
}

// Temp change until make pages for mobile, PM, startups
main .expertise a {
  color: var(--bs-heading-color);
} 

// **************
// Portfolio Page
.portfolio {

}
.portfolio .card-img-top.landscape, .portfolio .card-img-top.landscape img {
  width: 100% !important;
  height: auto !important;
}
.portfolio .card-img-top.portrait, .portfolio .card-img-top.portrait img {
  height: 100% !important;
  width: auto !important;
}
@include media-breakpoint-up(lg) {
  .portfolio .card-img-top {
    height: 240px;
  }
}
.portfolio .card-body li {
  padding-bottom: 0.6em;
}
@include media-breakpoint-down(sm) {
  .portfolio .card-footer img {
    height: 26px;
  }
}
.portfolio .areas .col {
  margin-bottom: 1em;
}

// *********************************
// Portfolio Item Pages (Area Pages)
.area li {
  padding-bottom: 0.5em;
}
.area li li:first-child {
  padding-top: 0.5em;
}
.area li:last-child {
  padding-bottom: 0;
}
.area dt {
  font-size: 1.1em;
}
.area dd {
  padding: 0.2em 1em 0.3em;
}
.area dd strong {
  font-weight: 600;
}
.area .li-p-0 li, .area .li-p-0 li li:first-child {
  padding-top: 0;
  padding-bottom: 0;
}